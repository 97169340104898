import { type Components, type Theme } from "@mui/material/styles";

import { applyPlatformAwareInteractionStyles } from "../../utils/applyPlatformAwareInteractionStyles";

declare module "@mui/material/Button" {
  interface ButtonOwnProps {
    /**
     * Inverts the button colors.
     *
     * Note: this can't be a boolean because MUI passes it down to the
     * HTML element, which doesn't support boolean values and shows errors
     * in the console.
     */
    invert?: "true" | "false";
    /**
     * For outlined buttons, this controls the thickness of the outline.
     *
     * Defaults to "regular"
     */
    outlineThickness?: "thick" | "regular";
  }
}

/**
 * Use Button from src/appV2/ShiftDiscovery/components/Button.tsx
 * instead of MUI Button.
 */
export const muiButtonOverrides: Components<Theme> = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      draggable: false,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.borderRadius?.xLarge,
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        border: "none",
        textTransform: "unset",
        lineHeight: "1.5rem",
        borderRadius: theme.borderRadius?.xLarge,
        fontWeight: theme.typography.fontWeightMedium,

        // These icons have negative margin by default,
        // we need to override it.
        "& .MuiButton-startIcon": {
          marginLeft: 0,
        },

        "& .MuiButton-endIcon": {
          marginRight: 0,
        },

        "&:hover, &:focus": {
          border: "none",
        },

        "&.Mui-disabled": {
          color: theme.palette.intent?.disabled.text,

          "& svg": {
            color: theme.palette.intent?.disabled.icon,
          },
        },
      }),
      sizeSmall: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        padding: "0.5rem 1.25rem", // 8px 20px
        height: theme.size?.button.small.height,

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: theme.size?.button.small.iconSize,
          height: theme.size?.button.small.iconSize,
        },
      }),
      sizeMedium: ({ theme }) => ({
        padding: "0.75rem 1.25rem", // 12px 20px
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        height: theme.size?.button.medium.height,

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: theme.size?.button.medium.iconSize,
          height: theme.size?.button.medium.iconSize,
        },
      }),
      sizeLarge: ({ theme }) => ({
        padding: "1.25rem 1.5rem", // 20px 24px
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        height: theme.size?.button.large.height,

        // The icon sizes in the designs don't match CbhIcon sizes,
        // so we need to override them here.
        "& svg": {
          width: theme.size?.button.large.iconSize,
          height: theme.size?.button.large.iconSize,
        },
      }),
      contained: ({ theme }) => ({
        ...applyPlatformAwareInteractionStyles({
          hoverStyle: {
            backgroundColor: theme.palette.primary?.dark,
          },
          defaultStyle: {
            backgroundColor: theme.palette.primary?.main,
          },
        }),

        "&.Mui-disabled": {
          backgroundColor: theme.palette.intent?.disabled.background,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.text?.primary,
              backgroundColor: theme.palette.background?.tertiary,

              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  backgroundColor: theme.palette.background?.primary,
                },
                defaultStyle: {
                  backgroundColor: theme.palette.background?.tertiary,
                },
              }),

              "&.Mui-disabled": {
                backgroundColor: theme.palette.intent?.disabled.background,
              },
            },
          },
        ],
      }),
      outlined: ({ theme }) => ({
        outlineColor: theme.palette.border?.primary,
        backgroundColor: theme.palette.background?.tertiary,
        outlineWidth: theme.borderWidth?.regular,
        outlineStyle: "solid",

        ...applyPlatformAwareInteractionStyles({
          hoverStyle: {
            outlineColor: theme.palette.border?.dark,
            backgroundColor: theme.palette.background?.tertiary,
          },
          defaultStyle: {
            outlineColor: theme.palette.border?.primary,
            backgroundColor: theme.palette.background?.tertiary,
          },
        }),

        "&.Mui-disabled": {
          outlineColor: theme.palette.border?.subtle,
        },

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.text?.invertPrimary,
              backgroundColor: "transparent",
              outlineWidth: theme.borderWidth?.regular,
              outlineColor: theme.palette.border?.primaryInvert,
              outlineStyle: "solid",

              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  outlineColor: theme.palette.border?.darkInvert,
                  backgroundColor: "transparent",
                },
                defaultStyle: {
                  outlineColor: theme.palette.border?.primaryInvert,
                  backgroundColor: "transparent",
                },
              }),

              "&.Mui-disabled": {
                color: theme.palette.text?.invertTertiary,
                outlineColor: theme.palette.border?.subtleInvert,

                "& svg": {
                  color: theme.palette.border?.darkInvert,
                },
              },
            },
          },
          {
            props: { outlineThickness: "thick" },
            style: {
              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  outlineWidth: theme.borderWidth?.thick,
                  outlineColor: theme.palette?.border?.subtleInvert,
                },
                defaultStyle: {
                  outlineWidth: theme.borderWidth?.regular,
                },
              }),
            },
          },
          {
            props: { outlineThickness: "thick", invert: "true" },
            style: {
              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  outlineColor: theme.palette?.border?.darkInvert,
                },
              }),
            },
          },
        ],
      }),
      // Used by our custom destructive buttons
      outlinedError: ({ theme }) => ({
        color: theme.palette.intent?.error.text,

        ...applyPlatformAwareInteractionStyles({
          hoverStyle: {
            outlineColor: theme.palette.intent?.error.border,
            backgroundColor: theme.palette.background?.tertiary,
          },
          defaultStyle: {
            backgroundColor: theme.palette.background?.tertiary,
          },
        }),

        variants: [
          {
            props: { invert: "true" },
            style: {
              color: theme.palette.intent?.error.text,
              backgroundColor: "transparent",

              ...applyPlatformAwareInteractionStyles({
                hoverStyle: {
                  outlineColor: theme.palette.intent?.error.border,
                  backgroundColor: "transparent",
                },
                defaultStyle: {
                  backgroundColor: "transparent",
                },
              }),
            },
          },
        ],
      }),
    },
  },
};
