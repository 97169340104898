import { type Components, type Theme } from "@mui/material/styles";

import { muiAvatarOverrides } from "./overrides/avatar";
import { muiBadgeOverrides } from "./overrides/badge";
import { muiBottomNavigationOverrides } from "./overrides/bottomNavigation";
import { muiButtonOverrides } from "./overrides/button";
import { muiCardOverrides } from "./overrides/card";
import { muiChipOverrides } from "./overrides/chip";
import { muiDialogOverrides } from "./overrides/dialog";
import { muiDrawerOverrides } from "./overrides/drawer";
import { muiIconButtonOverrides } from "./overrides/iconButton";
import { muiInputOverrides } from "./overrides/input";
import { muiListOverrides } from "./overrides/list";
import { muiPaperOverrides } from "./overrides/paper";
import { muiRatingOverrides } from "./overrides/rating";
import { muiSkeletonOverrides } from "./overrides/skeleton";
import { muiSliderOverrides } from "./overrides/slider";
import { muiTabsOverrides } from "./overrides/tabs";
import { muiToggleButtonOverrides } from "./overrides/toggleButton";

export const muiThemeComponents: Components<Theme> = {
  ...muiAvatarOverrides,
  ...muiBadgeOverrides,
  ...muiBottomNavigationOverrides,
  ...muiButtonOverrides,
  ...muiCardOverrides,
  ...muiChipOverrides,
  ...muiDialogOverrides,
  ...muiDrawerOverrides,
  ...muiIconButtonOverrides,
  ...muiInputOverrides,
  ...muiListOverrides,
  ...muiPaperOverrides,
  ...muiRatingOverrides,
  ...muiSliderOverrides,
  ...muiTabsOverrides,
  ...muiToggleButtonOverrides,
  ...muiSkeletonOverrides,
};
