/* eslint-disable max-lines */
import { type PaletteOptions } from "@mui/material";
/**
 * TODO: This file needs to be reorganized.
 * Rather than having consts like TEXT_PRIMARY_COLOR, we should have a base type for color palette of shape:
 *
 * interface Palette {
 *   text: {
 *     primary: string;
 *   }
 * }
 *
 * And then we should have separated light/dark files where we will manually assign these.
 *
 * Then we could create the theme with component overrides like this:
 *
 * const darkTheme = createTheme({
 *   palette: {
 *     mode: 'dark',
 *     ...darkPalette,
 *   },
 *   components: {
 *     MuiButton: {
 *       styleOverrides: {
 *         root: ({ theme }) => ({
 *           color: theme.palette.custom.textPrimary,
 *           backgroundColor: theme.palette.primary.main,
 *           '&:hover': {
 *             backgroundColor: theme.palette.primary.dark,
 *           },
 *         }),
 *       },
 *     },
 *   },
 * });
 *
 * !! We should start using functions when we need to access the theme in the styleOverrides !!
 */

/**
 * !! DO NOT IMPORT THESE COLORS DIRECTLY !!
 * Always use MUI Theme for colors and if any of these is not in the palette, add it
 */
export const WARM_GREY_00_COLOR = "#FFFFFF";
export const WARM_GREY_01_COLOR = "#F6F3F1";
export const WARM_GREY_02_COLOR = "#F1EDEA";
export const WARM_GREY_03_COLOR = "#E6E2DD";
export const WARM_GREY_04_COLOR = "#D5D1CE";
export const WARM_GREY_05_COLOR = "#B0AEAC";
export const WARM_GREY_06_COLOR = "#8B8987";
export const WARM_GREY_07_COLOR = "#3D414B";

export const COOL_GREY_01_COLOR = "#E9ECF2";
export const COOL_GREY_02_COLOR = "#D7DEE8";
export const COOL_GREY_03_COLOR = "#B4BECD";
export const COOL_GREY_04_COLOR = "#9CA9BD";
export const COOL_GREY_05_COLOR = "#7689A6";
export const COOL_GREY_06_COLOR = "#3B4C65";
export const COOL_GREY_07_COLOR = "#2F3C50";

export const BLUE_01_COLOR = "#E8F4FF";
export const BLUE_02_COLOR = "#CCE7FF";
export const BLUE_03_COLOR = "#B7DDFF";
export const BLUE_04_COLOR = "#98CCFB";
export const BLUE_05_COLOR = "#83AFEC";
export const BLUE_06_COLOR = "#0D2B57";
export const BLUE_07_COLOR = "#0C1C43";

export const PURPLE_01_COLOR = "#ECEBFF";
export const PURPLE_02_COLOR = "#D7D4FF";
export const PURPLE_03_COLOR = "#BDB8FE";
export const PURPLE_04_COLOR = "#9890FF";
export const PURPLE_05_COLOR = "#7269F0";
export const PURPLE_06_COLOR = "#5851C6";
export const PURPLE_07_COLOR = "#372FAA";

export const TEAL_01_COLOR = "#E1F5F6";
export const TEAL_02_COLOR = "#C9F2F4";
export const TEAL_03_COLOR = "#AEEDF1";
export const TEAL_04_COLOR = "#75D1D6";
export const TEAL_05_COLOR = "#45C1C8";
export const TEAL_06_COLOR = "#03929A";
export const TEAL_07_COLOR = "#004345";

export const GREEN_01_COLOR = "#E0FBEF";
export const GREEN_02_COLOR = "#C0F6D4";
export const GREEN_03_COLOR = "#ADF3C7";
export const GREEN_04_COLOR = "#75DEAF";
export const GREEN_05_COLOR = "#43C390";
export const GREEN_06_COLOR = "#169563";
export const GREEN_07_COLOR = "#094534";

export const YELLOW_01_COLOR = "#FFFBF6";
export const YELLOW_02_COLOR = "#FFF3E6";
export const YELLOW_03_COLOR = "#FFE7BC";
export const YELLOW_04_COLOR = "#FACE97";
export const YELLOW_05_COLOR = "#FFC884";
export const YELLOW_06_COLOR = "#BD6801";
export const YELLOW_07_COLOR = "#764100";

export const RED_01_COLOR = "#FCF2F0";
export const RED_02_COLOR = "#F9E1E1";
export const RED_03_COLOR = "#F7D0D0";
export const RED_04_COLOR = "#FCBDC1";
export const RED_05_COLOR = "#DB646A";
export const RED_06_COLOR = "#B01921";
export const RED_07_COLOR = "#82090F";

export const PRIMARY_COLOR = BLUE_07_COLOR;

export const generateSpinnerGradient = (hexColor: string) =>
  `conic-gradient(from 90deg at 50% 50%, ${hexColor}00 0deg, ${hexColor}0A 0.04deg, ${hexColor}FF 360deg)`;

declare module "@mui/material/styles" {
  interface IntentColorsMap {
    /**
     * This is called `surface` in figma
     */
    background: string;
    /**
     * This is called `surfaceInvert` in figma
     */
    backgroundInvert: string;
    border: string;
    text: string;
    icon: string;
  }

  // So far these are optional as module augmentation will affect the old theme and I want to avoid confusions
  interface CustomPalette {
    border?: {
      primary: string;
      dark: string;
      subtle: string;
      primaryInvert: string;
      subtleInvert: string;
      darkInvert: string;
    };
    icon?: {
      primary: string;
      disabled: string;
      lightInvert: string;
    };
    intent?: {
      neutral: IntentColorsMap;
      success: IntentColorsMap;
      warning: IntentColorsMap;
      error: IntentColorsMap;
      disabled: IntentColorsMap;
    };
    instantPay?: {
      background: string;
      text: string;
    };
    // This is intentionally exported separately so in the future we have the possibility to split base design system from hcp app.
    // If we were to continue using this design system for other apps, we could easily maintain a global one with generic colors
    // and only export HCP and shifts specific stuff separately.
    shift?: {
      urgent: IntentColorsMap & {
        gradient: string;
      };
      priority: IntentColorsMap & {
        gradient: string;
      };
      invite: IntentColorsMap & {
        gradient: string;
      };
      block: IntentColorsMap & {
        gradient: string;
      };
      general: {
        background: string;
        gradient: string;
      };
      day: {
        background: string;
        gradient: string;
      };
      evening: {
        background: string;
        gradient: string;
      };
      overnight: {
        background: string;
        gradient: string;
      };
    };
    components?: {
      locationMarker: {
        background: string;
        foreground: string;
        border: string;
      };
      clusterMarker: {
        background: string;
        foreground: string;
      };
      spinner: {
        primary: string;
        secondary: string;
        destructive: string;
        invert: string;
        generateGradient: (hexColor: string) => string;
      };
    };
    legacyFullScreenDialogBackground: string;
  }

  // Actual MUI types
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypeText {
    primary: string;
    secondary: string;
    tertiary?: string;
    invertPrimary?: string;
    invertSecondary?: string;
    invertTertiary?: string;
  }

  interface TypeBackground {
    default: string;
    paper: string;
    primary: string;
    secondary: string;
    tertiary?: string;
    invert?: string;
  }
}

export const muiThemePalette: PaletteOptions = {
  // MUI by default handles e.g pressed/hover states by applying a darker shade of the color/
  // In our designs we want to have a lighter shade for pressed/hover states, so we need to swap
  // dark and light
  primary: {
    main: PRIMARY_COLOR,
    contrastText: WARM_GREY_00_COLOR,
    dark: BLUE_06_COLOR,
    light: PRIMARY_COLOR,
  },
  secondary: {
    main: WARM_GREY_01_COLOR,
    contrastText: PRIMARY_COLOR,
  },
  error: {
    main: RED_06_COLOR,
    light: RED_07_COLOR,
    dark: RED_05_COLOR,
    contrastText: WARM_GREY_00_COLOR,
  },
  info: {
    main: BLUE_05_COLOR,
    light: BLUE_03_COLOR,
    dark: BLUE_04_COLOR,
    contrastText: WARM_GREY_00_COLOR,
  },
  warning: {
    main: YELLOW_04_COLOR,
    light: YELLOW_01_COLOR,
    dark: YELLOW_02_COLOR,
    contrastText: WARM_GREY_00_COLOR,
  },
  success: {
    main: GREEN_06_COLOR,
    light: GREEN_04_COLOR,
    dark: GREEN_05_COLOR,
    contrastText: WARM_GREY_00_COLOR,
  },
  background: {
    default: WARM_GREY_01_COLOR,
    paper: WARM_GREY_01_COLOR,
    primary: WARM_GREY_01_COLOR,
    secondary: WARM_GREY_02_COLOR,
    tertiary: WARM_GREY_00_COLOR,
    invert: PRIMARY_COLOR,
  },
  text: {
    primary: PRIMARY_COLOR,
    secondary: WARM_GREY_07_COLOR,
    tertiary: WARM_GREY_06_COLOR,
    disabled: WARM_GREY_05_COLOR,
    invertPrimary: WARM_GREY_00_COLOR,
    invertSecondary: COOL_GREY_03_COLOR,
    invertTertiary: COOL_GREY_04_COLOR,
  },
  action: {
    active: PRIMARY_COLOR,
  },
  divider: WARM_GREY_01_COLOR,
  border: {
    primary: WARM_GREY_04_COLOR,
    dark: WARM_GREY_05_COLOR,
    subtle: WARM_GREY_01_COLOR,
    primaryInvert: COOL_GREY_06_COLOR,
    subtleInvert: COOL_GREY_07_COLOR,
    darkInvert: COOL_GREY_03_COLOR,
  },
  icon: {
    primary: PRIMARY_COLOR,
    disabled: WARM_GREY_06_COLOR,
    lightInvert: WARM_GREY_00_COLOR,
  },
  intent: {
    neutral: {
      background: BLUE_03_COLOR,
      backgroundInvert: BLUE_06_COLOR,
      border: BLUE_04_COLOR,
      text: BLUE_07_COLOR,
      icon: BLUE_07_COLOR,
    },
    success: {
      background: GREEN_03_COLOR,
      backgroundInvert: GREEN_06_COLOR,
      border: GREEN_04_COLOR,
      text: GREEN_07_COLOR,
      icon: GREEN_07_COLOR,
    },
    warning: {
      background: YELLOW_04_COLOR,
      backgroundInvert: YELLOW_06_COLOR,
      border: YELLOW_06_COLOR,
      text: YELLOW_07_COLOR,
      icon: YELLOW_07_COLOR,
    },
    error: {
      background: RED_03_COLOR,
      backgroundInvert: RED_06_COLOR,
      border: RED_05_COLOR,
      text: RED_07_COLOR,
      icon: RED_06_COLOR,
    },
    disabled: {
      background: WARM_GREY_01_COLOR,
      backgroundInvert: WARM_GREY_03_COLOR,
      border: WARM_GREY_01_COLOR,
      text: WARM_GREY_06_COLOR,
      icon: WARM_GREY_06_COLOR,
    },
  },
  instantPay: {
    background: GREEN_03_COLOR,
    text: GREEN_07_COLOR,
  },
  shift: {
    urgent: {
      background: YELLOW_04_COLOR,
      backgroundInvert: YELLOW_06_COLOR,
      border: YELLOW_06_COLOR,
      text: YELLOW_07_COLOR,
      icon: YELLOW_07_COLOR,
      gradient: "linear-gradient(166.67deg, #FFE3C3 5.67%, #F9F5F2 49.8%)",
    },
    priority: {
      background: PURPLE_02_COLOR,
      backgroundInvert: PURPLE_07_COLOR,
      border: PURPLE_02_COLOR,
      text: PURPLE_07_COLOR,
      icon: PURPLE_07_COLOR,
      gradient: `linear-gradient(349.66deg, ${WARM_GREY_01_COLOR} 53.34%, ${PURPLE_03_COLOR} 93.29%)`,
    },
    invite: {
      background: PURPLE_02_COLOR,
      backgroundInvert: PURPLE_07_COLOR,
      border: PURPLE_02_COLOR,
      text: PURPLE_07_COLOR,
      icon: PURPLE_07_COLOR,
      gradient: `linear-gradient(349.66deg, ${WARM_GREY_01_COLOR} 53.34%, ${PURPLE_03_COLOR} 93.29%)`,
    },
    block: {
      background: TEAL_03_COLOR,
      backgroundInvert: TEAL_06_COLOR,
      border: TEAL_06_COLOR,
      text: TEAL_07_COLOR,
      icon: TEAL_07_COLOR,
      gradient: `linear-gradient(180deg, ${TEAL_03_COLOR} -5.14%, ${WARM_GREY_01_COLOR} 41.79%)`,
    },
    day: {
      background: BLUE_02_COLOR,
      gradient: `linear-gradient(203.57deg, #FFEAD3 -3.87%, #C9E5FF 46.38%, #F6F3F1 99.55%)`,
    },
    general: {
      background: BLUE_03_COLOR,
      gradient: `linear-gradient(180deg, ${BLUE_03_COLOR} 19.5%, ${WARM_GREY_01_COLOR} 100%)`,
    },
    evening: {
      background: BLUE_04_COLOR,
      gradient: `linear-gradient(13.25deg, #F6F3F1 12.49%, #FFD6C6 45.1%, #CAC2FC 93.75%)
`,
    },
    overnight: {
      background: BLUE_05_COLOR,
      gradient: `linear-gradient(4.21deg, #F6F3F1 11.97%, #B6CDF6 63.03%, #8F8DD9 104.25%);
`,
    },
  },
  components: {
    locationMarker: {
      background: PURPLE_03_COLOR,
      foreground: PURPLE_07_COLOR,
      border: WARM_GREY_00_COLOR,
    },
    clusterMarker: {
      background: WARM_GREY_00_COLOR,
      foreground: BLUE_07_COLOR,
    },
    spinner: {
      primary: BLUE_06_COLOR,
      secondary: BLUE_03_COLOR,
      destructive: RED_05_COLOR,
      invert: COOL_GREY_03_COLOR,
      generateGradient: generateSpinnerGradient,
    },
  },
  legacyFullScreenDialogBackground: WARM_GREY_01_COLOR,
};
/* eslint-enable max-lines */
